// 异常报错状态
export function FunError(status) {
  let text = ''
  switch (status) {
    case 1:
      text = '刷新了页面，第一次刷新';
      break;
    case 2:
      text = '刷新了页面，第二次刷新，已自动交卷';
      break;
    case 3:
      text = '切换了页面，第一次切屏';
      break;
    case 4:
      text = '切换了页面，第二次切屏，已自动交卷';
      break;
    case 5:
      text = '手动提交了试卷';
      break;
    case 6:
      text = '考试时长已结束，自动提交试卷';
      break;
    case 7:
      text = '退出页面，已自动交卷';
      break;
    case 8:
      text = '人脸活体对比失败';
      break;
    case 9:
      text = '人脸照片对比失败';
      break;
    case 10:
      text = '第一次退出页面';
      break;
  }
  return text
}

/* 判断各个浏览器版本号 */
function matchVesion() {
  var agent = navigator.userAgent.toLowerCase() ;
  // console.log("agent:", agent);
  var arr = [];
  var system = agent.split(' ')[1].split(' ')[0].split('(')[1];
  arr.push(system);
  var regStr_edge = /edge\/[\d.]+/gi;
  var regStr_ie = /trident\/[\d.]+/gi ;
  var regStr_ff = /firefox\/[\d.]+/gi;
  var regStr_chrome = /chrome\/[\d.]+/gi ;
  var regStr_saf = /safari\/[\d.]+/gi ;
  var regStr_opera = /opr\/[\d.]+/gi;
  //IE
  if(agent.indexOf("trident") > 0){
    arr.push(agent.match(regStr_ie)[0].split('/')[0]);
    arr.push(agent.match(regStr_ie)[0].split('/')[1]);
    return arr;
  }
  //Edge
  if(agent.indexOf('edge') > 0){
    arr.push(agent.match(regStr_edge)[0].split('/')[0]);
    arr.push(agent.match(regStr_edge)[0].split('/')[1]);
    return arr;
  }
  //firefox
  if(agent.indexOf("firefox") > 0){
    arr.push(agent.match(regStr_ff)[0].split('/')[0]);
    arr.push(agent.match(regStr_ff)[0].split('/')[1]);
    return arr;
  }
  //Opera
  if(agent.indexOf("opr")>0){
    arr.push(agent.match(regStr_opera)[0].split('/')[0]);
    arr.push(agent.match(regStr_opera)[0].split('/')[1]);
    return arr;
  }
  //Safari
  if(agent.indexOf("safari") > 0 && agent.indexOf("chrome") < 0){
    arr.push(agent.match(regStr_saf)[0].split('/')[0]);
    arr.push(agent.match(regStr_saf)[0].split('/')[1]);
    return arr;
  }
  //Chrome
  if(agent.indexOf("chrome") > 0){
    arr.push(agent.match(regStr_chrome)[0].split('/')[0]);
    arr.push(agent.match(regStr_chrome)[0].split('/')[1]);
    return arr;
  }
}

export function initBrowserVersion() {
  let browserMatch = matchVesion().toString();
  return browserMatch
}

