/**
* des 加密/解密方法
* 用途：敏感数据接口请求参数进行加密处理
*/

import CryptoJS from "crypto-js";
// 密钥key
const key = 'ALEF@2.*'

// DES加密
export const encryptDes = (message) => {
  const keyHex = CryptoJS.enc.Utf8.parse(key);
  const ivHex = CryptoJS.enc.Utf8.parse(String.fromCharCode(1, 2, 3, 4, 5, 6, 7, 8));

  const encrypted = CryptoJS.DES.encrypt(message, keyHex, {
    iv: ivHex,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.toString();
}

// DES解密
export const decryptDES = (encryptedMessage) => {
  const keyHex = CryptoJS.enc.Utf8.parse(key);
  const ivHex = CryptoJS.enc.Utf8.parse(String.fromCharCode(1, 2, 3, 4, 5, 6, 7, 8));

  const decrypted = CryptoJS.DES.decrypt({
    ciphertext: CryptoJS.enc.Base64.parse(encryptedMessage)
  }, keyHex, {
    iv: ivHex,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
}