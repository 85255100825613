<template>
  <div class="video_wap">
    <div class="video_box" v-show="isnotbtn">
      <video
        v-show="isnotbtn"
        ref="video"
        class="videoClass"
        autoplay
      ></video>
      <div class="pan-thumb" v-show="false">   <!-- 旋转边框 -->
        <div class="center"></div>
      </div>
    </div>
    <!-- 未识别到摄像头时图片替换 -->
    <img v-show="!isnotbtn" style="width:176px" src="@/assets/image/headIme.png" alt="">
    <div class="font">
      <img v-show="loading" src="@/assets/image/icon11.png" alt="">
      <img v-show="!loading && !isnotbtn" src="@/assets/image/icon7.png" alt="">
      <img v-show="!loading && isnotbtn" src="@/assets/image/icon8.png" alt="">
      <span>{{loading ? '摄像头唤起中' : isnotbtn ? '摄像头检测成功' : '摄像头检测失败'}}</span>
      <span class="loading" v-if="loading">...</span>
    </div>
  </div>
</template>  

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    isCameraShow: {
      type: Boolean
    },
  },
  // 数据对象
  data () {
    return {
      isnotbtn: false,
      loading:true,
    };
  },
  // 事件处理器
  methods: {
    // 调用摄像头
    callCamera() {
      // H5调用电脑摄像头API
      navigator.mediaDevices
        .getUserMedia({
          video: true,
        })
        .then((success) => {
          this.loading = false
          this.isnotbtn = true;
          // 摄像头开启成功
          this.$refs["video"].srcObject = success;
          // 实时拍照效果
          this.$refs["video"].play();
        })
        .catch((error) => {
          this.loading = false
        });
    },

    // 关闭摄像头
    closeCamera() {
      if (!this.$refs["video"].srcObject) {
        this.dialogCamera = false;
        return;
      }
      let stream = this.$refs["video"].srcObject;
      let tracks = stream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
      this.$refs["video"].srcObject = null;
      this.isnotbtn = false;
    },
    
    
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.callCamera()
  },
  // 生命周期-实例挂载后调用
  mounted () {
    
  },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    // 监听弹出开关
    isCameraShow(){
      clearTimeout(this.tiemID)
      if(this.isCameraShow){  // 当打开时唤起摄像头
        this.callCamera()
      } else {
        this.tiemID = setTimeout(()=>{
          this.closeCamera()  // 否则关闭
          this.loading = true
        },500)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.video_wap{
  padding-bottom: 16px;
}
.video_box{
  margin: 0 auto;
  overflow: hidden;
  width: 217px;
  height: 217px;
  position: relative;
  text-align: center;
  .videoClass {
    width: 209px;
    height: 209px;
    border-radius: 50%;
    object-fit: cover;
    position: absolute;
    left: 4px;
    top: 4px;
    z-index: 55;
  }

  /* 视频边框旋转加载边框 */
  .pan-thumb {
    position: absolute;
    left: 0;
    top: 0;
    width:217px;
    height:217px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: linear-gradient(rgba(45, 116, 221, 1),rgba(255, 255, 255, 1));
    animation: rotate 2s infinite linear;
  }
  .pan-thumb:hover {
    transform: rotate(-100deg);
  }
  .pan-thumb>.center{
    position: absolute;
    z-index: 50;
    width: 208px;
    height: 208px;
    border-radius: 50%;
    background-color: #ffffff;
  }

  /* 渐变色背景旋转动画 */
  @keyframes rotate {
    0% {
    transform: rotateZ(0);
    }
    100% {
    transform: rotateZ(360deg);
    }
  }
}

// 唤起时的三个点加载
.loading{
  display: inline-block; 
  height: 1em;
  line-height: 1;
  text-align: left;
  vertical-align: -.25em;
  overflow: hidden;
}
.loading::before {
  display: block;
  content: '...\A..\A.';
  white-space: pre-wrap;
  animation: loading 2s infinite step-start both;
}
@keyframes loading {
  33.3% { transform: translateY(-2em); }
  66.6% { transform: translateY(-1em); }
}

.font{
  margin-top: 12px;
  img{
    width: 16px;
    position: relative;
    top: -1px;
    margin-right: 4px;
  }
}
</style>