<template>
  <div class="main_wrap">
    <div class="error" v-if="isDisableExam">
      <img src="@/assets/image/error.png" alt="">
      网址暂不支持您当前使用的浏览器，为了避免出现问题，建议您更换浏览器
    </div>
    <!-- 左侧logo背景 -->
    <div class="side_div_L">
      <img src="@/assets/image/logo.png" alt="">
    </div>
    <!-- 右侧登录 -->
    <div class="side_div_R" :class="'side_div_Rmin'">
      <div class="center_div">
        <!-- <p class="p1">用 知 识 为 <i>爱</i> 赋 能</p> -->
        <!-- <p class="p2">Empowered by knowledge</p> -->
        <p class="p5"><img style="" src="@/assets/image/hxclass_font3.png" alt=""></p>
        <img class="font_img" src="@/assets/image/hxclass_font.png" alt="">
        <p><a-input v-model="formInfo.idcard" class="input" placeholder="请输入身份证号" /></p>
        <p><a-input v-model="formInfo.mobile" class="input" placeholder="请输入手机号" /></p>
        <p><a-button class="input" type="primary" :loading="loading" @click="onUserCheck()">登录</a-button></p>
        <p class="p3"><img src="@/assets/image/icon1.png" alt="">温馨提示: 请确认并允许使用摄像头</p>
        <div class="tips">
          <p class="p3" @click="isCameraShow = true"><img src="@/assets/image/icon2.png" alt="">检查摄像头</p>
          <p class="p3" @click="isBrowserShow = true"><img src="@/assets/image/icon3.png" alt="">建议浏览器</p>
        </div>
        <p class="p3 min_p3">
          <img src="@/assets/image/icon4.png" alt="">北京华夏元道文化科技有限公司
           <span>\</span>
          <img src="@/assets/image/icon5.png" alt="">400-800-9002
        </p>
        <div class="p3 min_p4">
          <p><img src="@/assets/image/icon4.png" alt="">北京华夏元道文化科技有限公司</p>
          <p><img src="@/assets/image/icon5.png" alt="">400-800-9002</p>
        </div>
      </div>
      <!-- 最小屏下该背景图显示 -->
      <img class="bgCover" src="@/assets/image/login_bg.png" alt="">
      <img class="logo" src="@/assets/image/logo.png" alt="">
    </div>
    <a-modal :maskClosable="false" v-model="isCameraShow" class="camear-modal" width="326px" align="center">
      <div class="Camear-title">检测摄像头</div>
      <Camear :isCameraShow="isCameraShow" />
    </a-modal>
    <a-modal :maskClosable="false" v-model="isBrowserShow" title="推荐浏览器" align="center">
      <div class="table">
        <a-table
          :columns="columns"
          bordered
          :rowKey="item=>item.id"
          :data-source="browserData" 
          :pagination="false"
          >
          <template slot="download" slot-scope="item">
            <a target="_blank" :href="item">下载</a>
          </template>
        </a-table>
      </div>
    </a-modal>
  </div>
</template>
<script>
import Camear from '@/components/Camear.vue'
import {encryptDes} from '@/utils/DESCypher.js' // 引用具体的方法
import { initBrowserVersion } from '@/utils/fun.js'
const columns = [
  {
    title: "浏览器",
    dataIndex: "name"
  },
  {
    title: "版本",
    dataIndex: "edition"
  },
  {
    title: "下载",
    dataIndex: "url",
    align: "center",
    scopedSlots: { customRender: "download" }
  }
]
export default {
  // 可用组件的哈希表
  components: {Camear},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      columns,
      isCameraShow: false,
      isBrowserShow: false,
      browserData:[
        {id:1, name:'谷歌', edition:'92.0.4515.107 及以上版本', url:'https://www.google.cn/chrome/'},
        {id:2, name:'火狐', edition:'101.0.0 及以上版本', url:'http://www.firefox.com.cn/download/#product-desktop-release'},
        {id:3, name:'360浏览器', edition:'13.0.6007.0 及以上版本', url:'https://browser.360.cn/se/'},
      ],
      loading: false,
      formInfo: {
        idcard: '', // 身份证
        mobile: '' // 手机号
      },
      limitBrowser:['IE'],  // 受限的浏览器
      isDisableExam: false,  // 是否禁用考试系统
    }
  },
  // 事件处理器
  methods: {
    // 校验身份
    onUserCheck(){
      if(this.isDisableExam){
        this.$message.error('您当前浏览器考试系统暂不支持，请更换建议浏览器后重试!');
        return
      }
      const idCheck = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      const mobileCheck = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
      if(!this.formInfo.idcard){
        this.$message.warning('请填写身份证号');
        return
      }
      // if(!idCheck.test(this.formInfo.idcard)){
      //   this.$message.warning('身份证格式输入有误');
      //   return
      // }
      if(!this.formInfo.mobile){
        this.$message.warning('请填写手机号');
        return
      }
      if(!mobileCheck.test(this.formInfo.mobile)){
        this.$message.warning('手机号格式输入有误');
        return
      }

      const encIdcard = encryptDes(this.formInfo.idcard)  //身份证号
      const encMobile = encryptDes(this.formInfo.mobile) // 手机号
      let idcard = encodeURIComponent(encIdcard)
      let mobile = encodeURIComponent(encMobile)

      let params = {
        idcard: idcard, // 身份证
        mobile: mobile // 手机号
      }

      // 身份证末尾x转大写
      this.formInfo.idcard = this.formInfo.idcard.toUpperCase()
      this.loading = true
      this.$ajax({
        method: 'post',
        url:"/exam/system/login",
        params: params
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          res.data.isLogin = true
          res.data.isDisable = true  // 考试认证状态初始为未认证  --
          res.data.isPassed = false  // 人脸识别初始是未识别   --
          res.data.isSubmit = false  // 是否提交试卷
          res.data.refreshNum = 0  //刷新次数
          res.data.screenNum = 0  //切屏次数
          res.data.backNum = 0  //返回次数
          this.funWatchError(res.data.examineeId)
          this.$store.commit("updateUserInfo", res.data);  // 更新个人信息
          this.$router.push({ path: "/ExamView" });
          this.$message.success('登录成功');
        } else {
          this.$message.warning(res.message);
        }
      }).catch(error => {
        this.loading = false
        if (error) {
          this.$message.error('系统错误');
        }
      })
    },

    // 存储浏览器记录
    funWatchError(examineeId){
      this.$ajax({
        method: "POST",
        url: "/exam/abnor-oper/save?content=设备环境" + '&examineeId=' + examineeId + '&status=1' + '&deviceInfo=' +  initBrowserVersion(),
      })
    },

    // 检测浏览器
    FunCheckBrowser(){
      var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
      var isOpera = userAgent.indexOf("Opera") > -1;
      if (isOpera) {//判断是否Opera浏览器
        return "Opera"
      }; 
      if (userAgent.indexOf("Firefox") > -1) {//判断是否Firefox浏览器
        return "FF";
      } 
      if (userAgent.indexOf("Chrome") > -1){//判断是否Chrome浏览器
        return "Chrome";
        }
      if (userAgent.indexOf("Safari") > -1) {//判断是否Safari浏览器
        return "Safari";
      } 
      if (userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera) {//判断是否IE浏览器
        return "IE";
      }; 
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.isDisableExam = this.limitBrowser.indexOf(this.FunCheckBrowser()) != -1
   },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.main_wrap{
  display: flex;
  height: 100vh;
  background-color: #ffffff;
  overflow: hidden;
  position: relative;
  .error{
    position: absolute;
    top: 0;
    width: 100%;
    height: 48px;
    line-height: 48px;
    color: #000000;
    background: #FEF9E5;
    text-align: center;
    z-index: 999;
    img{
      width: 14px;
      position: relative;
      bottom: 1px;
    }
  }
  .side_div_L{
    width: 44%;
    height: 100%;
    background-image: url('../assets/image/login_bg.png');
    display: inline-block;
    background-repeat: no-repeat;
    background-position:center;
    background-size: cover;
    position: relative;
    img{
      min-width: 156px;
      width: 8vw;
      margin-left: 7%;
      margin-top: 5%;
      background-repeat: no-repeat;
      object-fit: cover;
    }
  }
  .side_div_R{
    flex: 1;
    height: 100%;
    display:table;
    text-align: center;
    .center_div{
      z-index: 999;
      display:table-cell;
      vertical-align:middle;
      i{
        font-style: normal;
        color: #15B7DD;
      }
      >p{
        display: block;
        width: 100%;
        font-weight: 400;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      }
      .p1{
        font-size: 18px;
        color: #333333;
        margin-bottom: 18px;
      }
      .p2{
        font-size: 14px;
        font-weight: 300;
        color: #444444;
        margin-bottom: 30px;
      }
      .p3{
        margin-top: 30px;
        font-size: 20px;
        font-weight: 400;
        color: #666666;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
        span{
          margin: 0 10px;
        }
      }
      .p5 img{
        width:30%;
        margin-bottom:3%;
      }
      .min_p4{
        display: none;
      }
      .tips{
        width: 340px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        .p3{
          font-size: 22px;
          cursor: pointer;
        }
      }
      .min_p3{
        font-size: 18px;
        color: rgba(102,102,102,0.9500);
      }
      .font_img{
        width: 50%;
      }
      .input{
        border-radius: 60px;
        padding: 0 20px;
        width: 40%;
        height: 60px;
        font-size: 22px;
        margin-top: 40px;
      }
    }
    .bgCover{
      display: none;
    }
    .logo{
      display: none;
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1800px) {
    .side_div_R .center_div{
      .font_img{
        width: 54%;
      }
      .input{
        font-size: 16px;
        width: 50%;
        height: 50px;
        border-radius: 40px;
        margin-top: 20px;
      }
      .p5 img{
        width:40%;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .side_div_R .center_div{
      .p1{
        font-size: 16px;
        margin-bottom: 5px;
      }
      .p2{
        font-size: 12px;
        margin-bottom: 15px;
      }
      .p3{
        font-size: 16px;
        margin-top: 15px;
        img{
          width: 16px;
          height: 16px;
        }
      }
      .p5 img{
        width:50%;
      }
      .font_img{
        width: 60%;
      }
      .tips{
        width: 270px;
      }
      .input{
        font-size: 16px;
        width: 55%;
        height: 40px;
        border-radius: 40px;
        margin-top: 20px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .side_div_L{
      display: none;
    }
    .side_div_Rmin{
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      width: 100%;
      .center_div{
        display: block;
        width: 100%;
        text-align: center;
        .p1{
          font-size: 20px;
          margin-bottom: 5px;
        }
        .p2{
          font-size: 16px;
          margin-bottom: 15px;
        }
        .p5 img{
          width:50%;
        }
        .min_p3{
          font-size: 18px;
          display: none;
          min-width:500px
        }
        .min_p4{
          display: block;
          margin: 15px auto 0;
          text-align: left;
          width: 270px;
          >p{
            margin-bottom: 15px;
          }
        }
        .font_img{
          width: 80%;
        }
        .input{
          font-size: 16px;
          width: 70%;
          height: 40px;
          border-radius: 40px;
          margin-top: 20px;
        }
      }
      .bgCover{
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0.3;
      }
      .logo{
        display: block;
        position: absolute;
        top: 3%;
        left: 5%;
        width: 156px;
        height: 78px;
      }
    }
  }
}

.camear-modal{
  /deep/ .ant-modal-content{
    padding-top: 30px;
  }
  .Camear-title{
    margin-bottom: 30px;
    width: 150px;
    height: 40px;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    color: #000000;
    line-height: 40px;
    background: url('../assets/image/titlebg.png');
    background-size: 100% 100%;
  }
}

.table{
  padding: 10px 30px 30px;
}

/deep/ .ant-modal-header{
  border-bottom: none;
}
/deep/ .ant-modal-body{
  padding: 0;
}
/deep/ .ant-modal-footer{
  display: none;
}
</style>
